import { Link } from "gatsby"
import React from "react"
import { Layout } from "../components/Layout"
import "rc-tooltip/assets/bootstrap.css"
import {
  DemoButton,
  GetStartedButton,
  LearnMoreButton,
  CountsSection,
  Faq
} from "../commonComponents"
import { Helmet } from "react-helmet"

export default function CustomerHealth() {
  return (
    <Layout
      keywords="customer health dashboard, customer health score dashboard, customer 360 dashboard, customer 360 view dashboard, customer health dashboard, customer churn dashboard, churn analysis dashboard"
      description="Utilize our customer health dashboard to optimize retention strategies. Track customer health scores, analyze churn trend & get customer 360 view. Contact us to know more!"
      title="Customer Health Dashboard: Customer 360 View Dashboard & Churn Analysis"
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/customer-health/"
        />
      </Helmet>
      {/*  ======= Hero Section =======  */}

      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div className="col-md-6 section-padding-left">
            <h1 className="title-support-new-design">
              Understand your <br />
              customer's journey and get <span className="text-highlight">real-time health scores</span>
            </h1>

            <br />
            <div style={{ marginTop: "2%", display: "flex" }}>
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6 centered-image-wrapper">
            <img
              src="/img/customerHealth1.webp"
              alt="support agent"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <br/><br/>
      </section>
      {/*  End Hero  */}


      <section
        id="hero"
        className="section-padding-left d-flex align-items-center section-padding-right"
        style={{ background: "#F9F9F9", height: "auto", padding: "0" }}
      >
        <div
          className="row"
          style={{ height: "100%", marginTop: "5%", marginBottom: "5%" }}
        >
          <div className="col-md-6 web-view-new-design-display">
            <div>
              <div className="proactive-support-tittle">
                Real-time alerts when
                <br />
                customer sentiment drops
              </div>
              <ul className="proactive-support-engineering-teams">
                <li style={{ paddingLeft: "20px" }}>
                  Sentiment detection and trends from support
                  <br />
                  tickets to gauge customer unhappiness
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  We predict escalation and churn risk
                  <br />
                  proactively. Get daily and weekly reports
                  <br />
                  on customers whose customer health score
                  <br />
                  deteriorated and why
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  Don’t just rely on surveys and get 100%
                  <br />
                  coverage of all customers
                </li>
              </ul>
            </div>
            <div style={{ display: "flex", marginTop: "3%" }}>
              <GetStartedButton />
            </div>
          </div>
          <div className="col-md-6 mobile-view-new-design-display">
            <div>
              <div className="proactive-support-tittle ">
                Real-time alerts when <br />
                customer sentiment drops
              </div>
              <ul className="proactive-support-engineering-teams">
                <li style={{ paddingLeft: "20px" }}>
                  Sentiment detection and trends from
                  <br />
                  support tickets to gauge customer
                  <br />
                  unhappiness
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  We predict escalation and churn risk
                  <br />
                  proactively. Get daily and weekly
                  <br />
                  reports on customers whose score
                  <br />
                  deteriorated and why
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  Don’t just rely on surveys and get 100%
                  <br />
                  coverage of all customers
                </li>
              </ul>
            </div>
            <div style={{ display: "flex", marginTop: "3%" }}>
              <DemoButton />
            </div>
          </div>

          <div className="col-md-6 customer-health-graph-three-container">
            <img
              src="/img/customerHealth3.webp"
              alt="support agent"
              className="customer-health-graph-three clear-image-rendering"
            />
          </div>
        </div>
      </section>
      {/*  End Hero  */}

      {/*  ======= Hero Section =======  */}

      <section
        id="hero"
        className="section-padding-left section-padding-right"
        style={{ height: "auto", padding: "0" }}
      >
        <div
          className="row"
          style={{
            height: "100%",
            marginTop: "8%",
            marginBottom: "10%",
            marginLeft: "-9%",
          }}
        >
          <div className="col-md-6 customer-health-graph-four-container">
            <img
              className="customer-health-graph-four clear-image-rendering"
              src="/img/customerHealth4.webp"
              alt="support agent"
            />
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "90%" }}>
              <div className="proactive-support-tittle">
                Get a 360 view of every customer
              </div>
              <div className="proactive-support-description">
              <ul className="proactive-support-engineering-teams">
                <li style={{ paddingLeft: "20px" }}>
                Get real-time customer health scores based on 10+
                support and product signals and customer data.
                </li>
                <li style={{ paddingLeft: "20px" }}>
                 Tailor your conversation to the customer's product adoption journey
                </li>
                <li style={{ paddingLeft: "20px" }}>
                 Deeply empathize with customer experiences -- all with their
                 churn risk score, priority level and renewal dates at your fingertips.
                 </li>
              </ul>
              </div>
              <div style={{ display: "flex", marginTop: "3%" }}>
                <DemoButton />
              </div>
            </div>
          </div>
        </div>

        <div>
          <img
            className="customer-health-graph-four-mobile clear-image-rendering"
            src="/img/customerHealth4-mobile.webp"
            alt="support agent"
          />
        </div>
      </section>

      <section id="customer-boxes" className="web-view-new-design-display section-padding-right">
        <div
          className="row"
          style={{
            marginRight: "1%",
            marginLeft: "5%",
            marginBottom: "7%",
          }}
        >
          <div className="col-md-4">
            <div className="proactive-support-checkbox">
              <img
                src="/img/checkCircle.png"
                alt="checkCircle"
                style={{ height: 54 }}
              />
              <div className="text">
                We integrate with your CRMs
                <br />
                to know when a customer is up
                <br />
                for renewal
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="proactive-support-checkbox ">
              <img
                src="/img/checkCircle.png"
                alt="checkCircle"
                style={{ height: 54 }}
              />
              <div className="text">
                Know the customer’s product
                <br />
                adoption journey and customize
                <br />
                your conversations accordingly
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="proactive-support-checkbox ">
              <img
                src="/img/checkCircle.png"
                alt="checkCircle"
                style={{ height: 54 }}
              />
              <div className="text">
                Don’t give a cookie-cutter response
                to every customer but empathize
                deeply
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="customer-boxes" className="mobile-view-new-design-display section-padding-right">
        <div
          className="row"
          style={{
            marginRight: "1%",
            marginLeft: "5%",
            marginBottom: "7%",
          }}
        >
          <div className="col-md-4">
            <div className="proactive-support-checkbox">
              <img
                src="/img/checkCircle.png"
                alt="checkCircle"
                style={{ width: 36 }}
              />
              <div className="text">
                We integrate with your CRMs to know
                <br />
                when a customer is up for renewal
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="proactive-support-checkbox ">
              <img
                src="/img/checkCircle.png"
                alt="checkCircle"
                style={{ width: 36 }}
              />
              <div className="text">
                Know the customer’s product
                <br />
                adoption journey and customize your
                <br />
                conversations accordingly
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="proactive-support-checkbox ">
              <img
                src="/img/checkCircle.png"
                alt="checkCircle"
                style={{ width: 36 }}
              />
              <div className="text">
                Don’t give a cookie-cutter response
                <br />
                to every customer but empathize
                <br />
                deeply
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  End Hero  */}

      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="dark" />
      {/* <!-- End Counts Section --> */}

      <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What is a customer 360 view dashboard?"
                  answer="A Customer 360 View Dashboard is an advanced tool that provides a holistic view of customer interactions and behaviors. It collects customer data from various sources, such as data from sales, support, product, and engineering, to create a complete profile of each customer. It helps businesses better understand their customers, personalize customer experience, predict future behavior, and make informed business decisions."
                />
                <Faq
                  id="faq2"
                  question="How does a customer 360 view dashboard improve customer retention?"
                  answer="A customer 360 view dashboard or customer success dashboard is a critical tool in improving customer retention. It provides a detailed and comprehensive view of customer interactions, allowing businesses to address potential issues proactively. With metrics such as product usage, support requests, communication history, and feedback at a glance, the customer health dashboard enables businesses to identify customers at risk of churning quickly. This means companies can take immediate action to retain customers and improve their overall experience."
                />
                <Faq
                  id="faq3"
                  question="How can a customer health dashboard benefit customer support and customer success teams?"
                  answer="The customer health dashboard is a powerful tool that offers customer success and support teams a comprehensive view of customer interactions, behavior, and satisfaction levels. With a customer 360 dashboard, cusomer success team can have a holistic understanding of each customer's journey, customer health score, effectively identifying trends, issues, and opportunities. This data-driven approach enables prompt and personalized customer service, thus improving overall customer satisfaction and loyalty.

Moreover, by utilizing a customer churn dashboard, teams can monitor and analyze churn rates, helping them proactively identify at-risk customers and implement retention strategies. On the other hand, a customer health score dashboard provides an instant snapshot of a customer’s relationship with the business, facilitating targeted and effective communication. All these capabilities combined make these dashboards integral to maintaining a strong, healthy customer base and driving business growth."
                />
                <Faq
                  id="faq4"
                  question="What is the role of AI in a customer health dashboards?"
                  answer="The role of AI in a customer health dashboards is significant as it aids in gathering, processing, and analyzing vast amounts of customer data to provide actionable insights and customer health score. It helps customer success teams understand customers' behavior patterns, needs, and preferences. AI can predict customer churn and improve the overall customer health score by personalizing offers and interactions. AI provides a full customer 360 view dashboard and enables real-time reporting and analysis through the customer 360 dashboard and customer success dashboard.

In the customer churn dashboard context, AI can identify patterns and correlations much faster than manual analysis. Using machine learning algorithms, an efficient churn analysis dashboard can predict potential churn risks, enabling businesses to take preventive measures. These AI-driven insights from the customer health dashboard are invaluable for enhancing customer loyalty and reducing churn."
                />
                <Faq
                  id="faq5"
                  question="What metrics are typically included in a customer health dashboard?"
                  answer="A customer health dashboard generally consists of various metrics that provide insights into your customers' behavior and satisfaction levels. Commonly included metrics are customer lifetime value (CLV), net promoter score (NPS), support metrics such as customer satisfaction score (CSAT), support ticket volume, mean time to resolution (MTTR) of tickets, number of escalations, and customer sentiment. These metrics can provide a complete customer 360 dashboard view, highlighting key customer interaction points and identifying potential issues in advance. In a customer churn dashboard, the focus is more on metrics related to customer loss, like churn rate, attrition rate, and customer loss rate. A customer health score dashboard might integrate these metrics to help predict potential churn and implement proactive strategies. These dashboards help companies understand their customers better, improve their products or services, and make informed business decisions to drive growth and profitability."
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
